import React from "react";
import { Link } from "react-router-dom";
import { allRoutes } from "../../routes/routes";
import useLogin from "./useLogin";
import CommonTextInput from "../../components/common/commonTextInput/CommonTextInput";

const Login = () => {
  const { formData, onInputDataChange, onLoginHandler } = useLogin();
  return (
    <>
      <div className="d-flex flex-column flex-root app-root">
        <div className="main-body-cls">
          <section className="login-page">
            <div className="container custom-container">
              <div className="row justify-content-center mt-5">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card border-0">
                    <div className="card-title text-start border-bottom-0">
                      <h2 className="p-3">Log In</h2>
                    </div>
                    <div className="card-body">
                      <form
                        onSubmit={onLoginHandler}
                        className="form-custm-float-lb"
                      >
                        <div className="mb-4 position-relative">
                          <CommonTextInput
                            name="email"
                            onChange={onInputDataChange}
                            value={formData.email}
                            className="form-control"
                            id="userId"
                            placeholder="Enter Email"
                          />
                          <label
                            htmlFor="userId"
                            className="form-label flt-label"
                          >
                            Email ID
                          </label>
                        </div>
                        <div className="mb-1 position-relative">
                          <CommonTextInput
                            name="password"
                            value={formData.password}
                            className="form-control pe-5"
                            id="password" 
                            type="password"
                            placeholder="Enter Password"
                            onChange={onInputDataChange}
                          />
                          <i
                            className="fa fa-eye-slash showps"
                            id="pasIco"
                            onclick="show_passwors()"
                          />
                          <label
                            htmlFor="password"
                            className="form-label flt-label"
                          >
                            Password
                          </label>
                        </div>
                        <div className="mb-4 text-end">
                          <Link
                            to={allRoutes.forgotPassword}
                            className="forgot-pass"
                          >
                            Forgot password?
                          </Link>
                        </div>
                        <div className="md-4 text-center">
                          <button
                            type="submit"
                            onClick={onLoginHandler}
                            className="btn btn-info button-drk-info"
                          >
                            LOG IN
                          </button>
                        </div>
                        <div className="md-4 text-center">
                          <p className="dnt-ac-tx">
                            Don’t have an account?{" "}
                            <Link to={allRoutes.signUp}>Create Account</Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Login;
