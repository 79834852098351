import React from "react";

const CommonTextInput = ({
  name,
  id,
  type = "",
  className = "",
  placeholder = "",
  onChange = () => {},
  onSubmit = () => {},
  value = "",
  style = {},
  props,
  checked = false,
  mandatory = false,
  maxLength,
}) => {
  return (
    <input
      type={type}
      className={className}
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      value={value}
      style={style}
      maxLength={maxLength}
      security
      onKeyDown={onSubmit}
      placeholder={`${placeholder} ${mandatory ? "*" : ""}`}
      {...props}
    />
  );
};

export default CommonTextInput;
