import moment from "moment";
import { jsonToFormData, sendDataToReducer } from "../../helpers/CommonHelpers";
import {
  changePasswordService,
  deleteAccountService,
  deleteFamilyMemberService,
  deleteMyDataService,
  deleteShareWithMeService,
  deleteSharedDocService,
  getInviteFrinedService,
  getNotificationFrequencyService,
  getNotificationListService,
  getNotificationManagementService,
  getShareWithAnyOneLinkService,
  getShareWithPeopleLinkService,
  getSharedByMeService,
  getSharedDocDetailsService,
  getSharedWithMeService,
  getUserBusinessCardSettingService,
  sendHelpCenterQueryService,
  sendMailService,
  storeNotificationManagementDataService,
  updateFavoriteDataListService,
  updateShareLinkService,
  updateUserBusinessCardInfoService,
  verifyEmailService,
  verifyMailService,
  verifyUserService,
} from "../../services/Services";
import axiosinstance from "../../services/axiosinstance";
import CustomToast from "../../toastify/CustomToast";
import {
  GET_COUNTRY_LIST_SUCCESS,
  GET_DOCUMENT_FIELDS_LIST_SUCCESS,
  GET_DOCUMENT_TYPE_LIST_SUCCESS,
  GET_FAMILY_DOCUMENT_LIST_SUCCESS,
  GET_FAMILY_MEMBER_SUCCESS,
  GET_GOOGLEPLACE_LIST_SUCCESS,
  GET_RELATION_LIST_SUCCESS,
  GET_WISHLIST_SUCCESS,
  INVITE_FRIEND_DESC,
  NOTIFICATION_FREQUENCY_LIST,
  NOTIFICATION_MANAGEMENT_LIST,
  SET_DOCUMENT_LIST_BY_DOCTYPE,
  SET_DOCUMENT_LIST_BY_FAMILY,
  SET_NOTIFICATION_LIST,
  SET_SHARED_BY_ME,
  SET_SHARED_DOC_DETAILS,
  SET_SHARED_WITH_ME,
  SET_WISHLIST,
} from "../reducers/commonReducer";
import { getAuthToken, getUserDataSelelctor } from "../selectors/AuthSelectors";
import {
  getDocumentListByDocTypeSelector,
  getDocumentListByFamilySelector,
  getSharedByMeDataSelector,
  getSharedDocDetailsSelector,
  getSharedWithMeDataSelector,
} from "../selectors/CommonSelectors";
import {
  GOOGLE_API_KEY,
  NUMBER_OF_FAVOURITES_LIMIT,
} from "../../constants/constants";
import i18n from "../../i18n/i18n";
import axios from "axios";
import { UPDATE_BUSINESS_CARD_SETTING } from "../reducers/AuthReducer";
import { getBusinessCardDataAction, logoutAction } from "./AuthActions";

const CommonError = {
  message: "Something Went Wrong",
  status: false,
};

// Get Notification Frequency Data
export const getNotificationFrequncyAction = () => (dispatch) =>
  new Promise((resolve, reject) => {
    getNotificationFrequencyService()
      .then((res) => {
        sendDataToReducer(dispatch, NOTIFICATION_FREQUENCY_LIST, res?.data);
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// Get Notification Management Data
export const getNotificationManagementAction = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const token = getAuthToken(state);
    getNotificationManagementService(token)
      .then((res) => {
        sendDataToReducer(dispatch, NOTIFICATION_MANAGEMENT_LIST, res?.data);
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// Store Notification Management Data
export const storeNotificationManagementDataAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    storeNotificationManagementDataService(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        const errorResponse = error?.response?.data?.error;
        CustomToast.error(
          errorResponse?.[Object.keys(errorResponse)?.[0]]?.[0]
        );
        reject({ error });
      });
  });

// Get Business Card Setting
export const getNotificationListAction = () => (dispatch) =>
  new Promise((resolve, reject) => {
    getNotificationListService()
      .then((res) => {
        sendDataToReducer(dispatch, SET_NOTIFICATION_LIST, res?.data?.data);
        resolve(res?.data?.data);
      })
      .catch((error) => {
        reject({ error });
      });
  });

// Get Invite friend Description
export const getInviteFriendDescription = () => (dispatch) =>
  new Promise((resolve, reject) => {
    getInviteFrinedService()
      .then((res) => {
        sendDataToReducer(
          dispatch,
          INVITE_FRIEND_DESC,
          res?.data?.data?.[0]?.description
        );
        resolve(res?.data?.data);
      })
      .catch((error) => {
        reject({ error });
      });
  });

export function ADD_REMOVE_WISHLIST(data, favouriteListData) {
  return async (dispatch, getState) => {
    const state = getState();
    const documentListByFamilyData = [
      ...getDocumentListByFamilySelector(state),
    ];
    const documentListByDocTypeData = [
      ...getDocumentListByDocTypeSelector(state),
    ];
    const favItemIndex = favouriteListData?.findIndex(
      (item) =>
        item?.doc_id === data?.document_id &&
        item?.doc_type_id === data?.document_type_id &&
        item?.family_member_id === data?.family_member_id
    );
    if (
      favItemIndex >= 0 ||
      favouriteListData?.length < NUMBER_OF_FAVOURITES_LIMIT
    ) {
      const docByFamilyDataFirstIndex = documentListByFamilyData.findIndex(
        (item) => item?.id === data?.family_member_id
      );
      const docByFamilyDataSecondIndex = documentListByFamilyData[
        docByFamilyDataFirstIndex
      ]?.list.findIndex((item) => item?.id === data?.document_type_id);
      const docByFamilyDataThirdIndex = documentListByFamilyData[
        docByFamilyDataFirstIndex
      ]?.list[docByFamilyDataSecondIndex]?.document_list.findIndex(
        (item) => item?.doc_id === data?.document_id
      );
      if (
        docByFamilyDataFirstIndex >= 0 &&
        docByFamilyDataSecondIndex >= 0 &&
        docByFamilyDataThirdIndex >= 0
      ) {
        const selectedFamilyDataRow =
          documentListByFamilyData[docByFamilyDataFirstIndex]?.list[
            docByFamilyDataSecondIndex
          ]?.document_list[docByFamilyDataThirdIndex];
        documentListByFamilyData[docByFamilyDataFirstIndex].list[
          docByFamilyDataSecondIndex
        ].document_list[docByFamilyDataThirdIndex].wishlist =
          selectedFamilyDataRow.wishlist === 1 ? 0 : 1;
        sendDataToReducer(
          dispatch,
          SET_DOCUMENT_LIST_BY_FAMILY,
          documentListByFamilyData
        );
        if (selectedFamilyDataRow.wishlist === 1) {
          favouriteListData.splice(favItemIndex, 1);
          sendDataToReducer(dispatch, SET_WISHLIST, favouriteListData);
        }
      }

      const docByDocTypeDataFirstIndex = documentListByDocTypeData.findIndex(
        (item) => item?.id === data?.document_type_id
      );
      const docByDocTypeDataSecondIndex = documentListByDocTypeData[
        docByDocTypeDataFirstIndex
      ]?.list.findIndex((item) => item?.id === data?.family_member_id);
      const docByDocTypeDataThirdIndex = documentListByDocTypeData[
        docByDocTypeDataFirstIndex
      ]?.list[docByDocTypeDataSecondIndex]?.document_list.findIndex(
        (item) => item?.doc_id === data?.document_id
      );
      if (
        docByDocTypeDataFirstIndex >= 0 &&
        docByDocTypeDataSecondIndex >= 0 &&
        docByDocTypeDataThirdIndex >= 0
      ) {
        const selectedFamilyDataRow =
          documentListByDocTypeData[docByDocTypeDataFirstIndex]?.list[
            docByDocTypeDataSecondIndex
          ]?.document_list[docByDocTypeDataThirdIndex];
        documentListByDocTypeData[docByDocTypeDataFirstIndex].list[
          docByDocTypeDataSecondIndex
        ].document_list[docByDocTypeDataThirdIndex].wishlist =
          selectedFamilyDataRow.wishlist === 1 ? 0 : 1;
        sendDataToReducer(
          dispatch,
          SET_DOCUMENT_LIST_BY_DOCTYPE,
          documentListByDocTypeData
        );
      }
      try {
        const response = await axiosinstance.post("add-remove-favorite", data);
        if (favItemIndex < 0) dispatch(GET_WISHLIST());
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    } else {
      CustomToast.error(i18n.t("favoritelist_is_full"));
    }
  };
}

export function GET_WISHLIST() {
  return async (dispatch) => {
    try {
      const response = await axiosinstance.get("get-favorite-list");
      const responseJson = response.data;
      dispatch({
        type: GET_WISHLIST_SUCCESS,
        payload: responseJson,
      });
      return Promise.resolve(responseJson);
    } catch (e) {
      return Promise.reject(CommonError);
    }
  };
}

export function GET_COUNTRY_LIST() {
  return async (dispatch) => {
    try {
      const response = await axiosinstance.get("get-country-list");
      const responseJson = response.data;
      dispatch({
        type: GET_COUNTRY_LIST_SUCCESS,
        payload: responseJson,
      });
      return Promise.resolve(responseJson);
    } catch (e) {
      return Promise.reject(CommonError);
    }
  };
}

const isExpiredInDays = (expiryDate) => {
  if (expiryDate) {
    const firstDate = moment(new Date(expiryDate));
    const secondDate = moment(firstDate)
      .date(new Date().getDate())
      .month(new Date().getMonth())
      .year(new Date().getFullYear());
    const diffDays = firstDate.diff(secondDate, "days");
    return diffDays;
  }
  return null;
};

export function updateFavoriteListAction(data) {
  return async (dispatch) =>
    new Promise((resolve, reject) => {
      updateFavoriteDataListService(data)
        .then((res) => {
          dispatch(GET_WISHLIST()).then(() => {
            CustomToast.success(i18n.t("removed_from_wishilist"));
          });
          resolve(res);
        })
        .catch(reject);
    });
}
export function getSharedByMeAction() {
  return async (dispatch) =>
    new Promise((resolve, reject) => {
      getSharedByMeService()
        .then((res) => {
          const data = [];
          res?.data?.map((item) => {
            const expiredInDays = isExpiredInDays(item?.expiry_date);
            if (expiredInDays >= -3) {
              data?.push(item);
            }
          });
          sendDataToReducer(dispatch, SET_SHARED_BY_ME, data);
          resolve(res?.data);
        })
        .catch(reject);
    });
}

export function getSharedWithMeAction() {
  return async (dispatch) =>
    new Promise((resolve, reject) => {
      getSharedWithMeService()
        .then((res) => {
          const data = [];
          res?.data?.map((item) => {
            const expiredInDays = isExpiredInDays(item?.expiry_date);
            if (expiredInDays >= -3) {
              data?.push(item);
            }
          });
          sendDataToReducer(dispatch, SET_SHARED_WITH_ME, data);
          resolve(res?.data);
        })
        .catch(reject);
    });
}

export const getSharedDocDetailsAction =
  (share_docs_no) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      getSharedDocDetailsService(share_docs_no)
        .then(async (res) => {
          sendDataToReducer(dispatch, SET_SHARED_DOC_DETAILS, res);
          resolve(res);
        })
        .catch(reject);
    });

export const deleteFamilyMemberAction = (data) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    deleteFamilyMemberService(data)
      .then(async (res) => {
        resolve(res);
      })
      .catch((e) => reject(e));
  });

export const resetSharedDocDetailsAction = () => (dispatch) =>
  new Promise((resolve) => {
    sendDataToReducer(dispatch, SET_SHARED_DOC_DETAILS, {});
    resolve(true);
  });

export const deleteSharedDocAction = (share_docs_no) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const shareByMe = getSharedByMeDataSelector(state);
    const filteredShareByMeData = shareByMe.filter(
      (item) => item?.share_docs_no !== share_docs_no
    );
    deleteSharedDocService(share_docs_no)
      .then(() => {
        sendDataToReducer(dispatch, SET_SHARED_BY_ME, filteredShareByMeData);
        resolve(true);
      })
      .catch(reject);
  });

export const deleteSharedWithMeAction =
  (share_docs_no) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      const shareWithMe = getSharedWithMeDataSelector(state);
      const filteredShareByWithData = shareWithMe.filter(
        (item) => item?.share_docs_no != share_docs_no
      );
      deleteShareWithMeService(share_docs_no)
        .then(() => {
          sendDataToReducer(
            dispatch,
            SET_SHARED_WITH_ME,
            filteredShareByWithData
          );
          resolve(true);
        })
        .catch(reject);
    });

export const deleteMyDataAction = () => (dispatch) =>
  new Promise((resolve) => {
    sendDataToReducer(dispatch, SET_WISHLIST, []);
    sendDataToReducer(dispatch, SET_NOTIFICATION_LIST, []);
    sendDataToReducer(dispatch, SET_SHARED_BY_ME, []);
    sendDataToReducer(dispatch, SET_SHARED_WITH_ME, []);
    sendDataToReducer(dispatch, SET_SHARED_DOC_DETAILS, []);
    sendDataToReducer(dispatch, GET_FAMILY_DOCUMENT_LIST_SUCCESS, {
      documentListByFamily: [],
      documentListByDocType: [],
    });
    resolve(true);
  });

//  Delete Data Action
export const getDeleteMyDataAction = () => (dispatch) =>
  new Promise((resolve, reject) => {
    deleteMyDataService()
      .then((res) => {
        dispatch(deleteMyDataAction());
        resolve(res);
      })
      .catch((error) => {
        reject({ error });
      });
  });

//  Delete Account Action
export const deleteMyAccountAction = () => (dispatch) =>
  new Promise((resolve, reject) => {
    deleteAccountService()
      .then((res) => {
        dispatch(logoutAction());
        resolve(res);
      })
      .catch((error) => {
        reject({ error });
      });
  });

export function ADD_MEMBER(data) {
  return async (dispatch) => {
    try {
      const response = await axiosinstance.post("add-family-member", data);
      const responseJson = response.data;
      if (responseJson) {
        return { response: responseJson };
      }
      return { response: responseJson };
    } catch (e) {
      return { response: CommonError };
    }
  };
}

export function GET_MEMBER_LIST() {
  return async (dispatch, getState) => {
    const state = getState();
    const loginToken = getAuthToken(state);
    try {
      const response = await axiosinstance.get(
        `get-family-member/${loginToken}`
      );
      const responseJson = response.data;
      dispatch({
        type: GET_FAMILY_MEMBER_SUCCESS,
        payload: responseJson,
      });
      return Promise.resolve(responseJson);
    } catch (e) {
      return { response: CommonError };
    }
  };
}

export function GET_DOCUMENT_TYPE_LIST() {
  return async (dispatch) => {
    try {
      const response = await axiosinstance.get("get-document-type");
      const responseJson = response.data;
      dispatch({
        type: GET_DOCUMENT_TYPE_LIST_SUCCESS,
        payload: responseJson,
      });
      return { response: responseJson };
    } catch (e) {
      // return ({ response: CommonError })
      return { response: CommonError };
    }
  };
}

export function GET_DOCUMENT_FIELDS_LIST(id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axiosinstance
        .get(`get-document-type-byId/${id}`)
        .then((response) => {
          const responseJson = response.data;
          dispatch({
            type: GET_DOCUMENT_FIELDS_LIST_SUCCESS,
            payload: responseJson,
          });
          resolve({ response: responseJson });
        })
        .catch((e) => {
          reject({ response: CommonError });
        });
    });
}

export function GET_GOOGLEPLACE_LIST(searchKey) {
  const data = {
    key: GOOGLE_API_KEY,
    input: searchKey,
  };
  return async (dispatch) => {
    try {
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/place/autocomplete/json",
        { params: data }
      );
      const responseJson = response.data;
      dispatch({
        type: GET_GOOGLEPLACE_LIST_SUCCESS,
        payload: responseJson,
      });
      return { response: responseJson };
    } catch (e) {
      return { response: CommonError };
    }
  };
}

export function EDIT_MEMBER(data) {
  return async () => {
    try {
      const response = await axiosinstance.post("update-family-member", data);
      const responseJson = response.data;
      return { response: responseJson };
    } catch (e) {
      return { response: CommonError };
    }
  };
}

export function ADD_DOCUMENT(formData) {
  return async (dispatch) => {
    new Promise((resolve, reject) => {
      axiosinstance
        .post("store-documents", formData)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e ?? CommonError);
        });
    });

    // if (selectedFile1?.uri) {
    //     rnFetchBlob.copyFile(selectedFile1?.uri, responseJson?.file_url)
    // }
    // if (selectedFile2?.uri) {
    //     rnFetchBlob.copyFile(selectedFile2?.uri, responseJson?.file_url2)
    // }
    // if (selectedFile3?.uri) {
    //     rnFetchBlob.copyFile(selectedFile3?.uri, responseJson?.file_url3)
    // }
    // if (selectedFile4?.uri) {
    //     rnFetchBlob.copyFile(selectedFile4?.uri, responseJson?.file_url4)
    // }
    // if (responseJson?.thumb) {
    //     FastImage.preload([
    //         { uri: GLOBAL_PDF_LINK(responseJson?.thumb) },
    //     ])
    // }
    // if (responseJson?.thumb2) {
    //     FastImage.preload([
    //         { uri: GLOBAL_PDF_LINK(responseJson?.thumb2) },
    //     ])
    // }
    // if (responseJson?.thumb3) {
    //     FastImage.preload([
    //         { uri: GLOBAL_PDF_LINK(responseJson?.thumb3) },
    //     ])
    // }
    // if (responseJson?.thumb4) {
    //     FastImage.preload([
    //         { uri: GLOBAL_PDF_LINK(responseJson?.thumb4) },
    //     ])
    // }
  };
}

export function UPDATE_DOCUMENT(formData) {
  return async () => {
    try {
      const response = await axiosinstance.post("update-documents", formData);
      // if (selectedFile1?.uri) {
      //     rnFetchBlob.copyFile(selectedFile1?.uri, responseJson?.file_url)
      // }
      // if (selectedFile2?.uri) {
      //     rnFetchBlob.copyFile(selectedFile2?.uri, responseJson?.file_url2)
      // }
      // if (selectedFile3?.uri) {
      //     rnFetchBlob.copyFile(selectedFile3?.uri, responseJson?.file_url3)
      // }
      // if (selectedFile4?.uri) {
      //     rnFetchBlob.copyFile(selectedFile4?.uri, responseJson?.file_url4)
      // }
      // if (responseJson?.thumb) {
      //     FastImage.preload([
      //         { uri: GLOBAL_PDF_LINK(responseJson?.thumb) },
      //     ])
      // }
      // if (responseJson?.thumb2) {
      //     FastImage.preload([
      //         { uri: GLOBAL_PDF_LINK(responseJson?.thumb2) },
      //     ])
      // }
      // if (responseJson?.thumb3) {
      //     FastImage.preload([
      //         { uri: GLOBAL_PDF_LINK(responseJson?.thumb3) },
      //     ])
      // }
      // if (responseJson?.thumb4) {
      //     FastImage.preload([
      //         { uri: GLOBAL_PDF_LINK(responseJson?.thumb4) },
      //     ])
      // }
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(CommonError);
    }
  };
}

export function RESET_PASSWORD(data) {
  return async () => {
    try {
      const response = await axiosinstance.post("reset-password", data);
      const responseJson = response.data;
      return { response: responseJson };
    } catch (e) {
      return { response: e };
    }
  };
}

export function GET_TERMS_CONDITION() {
  return async (dispatch) => {
    try {
      const response = await axiosinstance.get(`term-service/${1}`);
      const responseJson = response.data;
      return Promise.resolve(responseJson);
    } catch (e) {
      return Promise.reject(CommonError);
    }
  };
}

export function GET_PRIVACY_POLICY() {
  return async (dispatch) => {
    try {
      const response = await axiosinstance.get(`privacy-policy/${1}`);
      const responseJson = response.data;
      return Promise.resolve(responseJson);
    } catch (e) {
      return Promise.reject(CommonError);
    }
  };
}

export function GET_FAMILY_DOCUMENT_LIST() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const loginToken = getAuthToken(state);
      const response = await axiosinstance.get(`document-list/${loginToken}`);
      const responseJson = response.data;
      dispatch({
        type: GET_FAMILY_DOCUMENT_LIST_SUCCESS,
        payload: responseJson,
      });
      return Promise.resolve(responseJson);
    } catch (e) {
      return Promise.reject(CommonError);
    }
  };
}

export function GET_RELATION_LIST() {
  return async (dispatch) => {
    try {
      const response = await axiosinstance.get("get-relations");
      const responseJson = response.data;
      dispatch({
        type: GET_RELATION_LIST_SUCCESS,
        payload: responseJson,
      });
      return Promise.resolve(responseJson);
    } catch (e) {
      return Promise.reject(CommonError);
    }
  };
}

// Update Share Link Service
export const updateShareLinkAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    updateShareLinkService(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject({ error });
      });
  });

//  Get Share With AnyOne Link Action
export const getShareWithAnyOneLinkAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    getShareWithAnyOneLinkService(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject({ error });
      });
  });

//  Get Share With People Link Action
export const getShareWithPeopleLinkAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    getShareWithPeopleLinkService(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject({ error });
      });
  });

// HelpCenter Service
export const getHelpCenterAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    sendHelpCenterQueryService(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject({ error });
      });
  });
// Get Business Card Setting
export const getBusinessCardSettingAction = () => (dispatch) =>
  new Promise((resolve, reject) => {
    getUserBusinessCardSettingService()
      .then((res) => {
        sendDataToReducer(dispatch, UPDATE_BUSINESS_CARD_SETTING, res?.data);
        resolve(res?.data);
      })
      .catch((error) => {
        reject({ error });
      });
  });

// Update Business Card Data
export const updateBusinessCardDataAction = (data) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const userData = getUserDataSelelctor(state);
    data.user_id = userData?.id;
    updateUserBusinessCardInfoService(jsonToFormData(data))
      .then((res) => {
        dispatch(getBusinessCardDataAction());
        dispatch(getBusinessCardSettingAction()).finally(() => {
          resolve(res);
        });
      })
      .catch((error) => {
        const errorResponse = error?.response?.data?.error;
        CustomToast.error(
          errorResponse?.[Object.keys(errorResponse)?.[0]]?.[0]
        );
        reject({ error });
      });
  });

export const getNotificationFrequencyAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    getNotificationFrequencyService()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(CommonError);
      });
  });

export const verifyUserServcieAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    verifyUserService(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(CommonError);
      });
  });

export const sendMailServiceAction = () => (dispatch) =>
  new Promise((resolve, reject) => {
    sendMailService()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(CommonError);
      });
  });

export const verifyMailServiceAction = (userOTP) => (dispatch) =>
  new Promise((resolve, reject) => {
    verifyMailService(userOTP).then((res) => {
      resolve(res);
    });
  });

export const verifyEMailServiceAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    verifyEmailService(data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });

export const changePasswordAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    changePasswordService(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(CommonError);
      });
  });

export function DELETE_DOCUMENT_DETAILS(documentId) {
  return async () => {
    try {
      const response = await axiosinstance.get(`delete-document/${documentId}`);
      const responseJson = response.data;
      return Promise.resolve(responseJson);
    } catch (e) {
      return Promise.reject(CommonError);
    }
  };
}

const callDownloadDocumentApi = (document) =>
  new Promise((resolve, reject) => {
    const documentId = document?.doc_id ?? document?.document_id;
    axiosinstance
      .get(`download-document/${documentId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject({ message: "Failed to Download file from server", error });
      });
  });

export const DOWNLOAD_DOCUMENT_DETAILS = (document) => () =>
  new Promise((resolve, reject) => {
    callDownloadDocumentApi(document).then(resolve).catch(reject);
  });

export function GET_DOCUMENT_DETAILS(documentId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const loginToken = getAuthToken(state);
      const response = await axiosinstance.get(
        `get-documents-byId/${loginToken}/${documentId}`
      );
      const responseJson = response.data;
      return Promise.resolve(responseJson);
    } catch (e) {
      return Promise.reject(CommonError);
    }
  };
}
