import googleMap from "./social/google-maps.png";
import instagram from "./social/instagram.png";
import linkedin from "./social/linkedin.png";
import twitter from "./social/twitter.png";
import whatsapp from "./social/whatsapp.png";
import pdfImage from "./pdf.png";
import notificationIcon from "./notificationIcon.svg";
import familyMemberIcon from "./familyMemberIcon.svg";
import helpCenterIcon from "./helpCenter.svg";
import logOutIcon from "./logOutIcon.svg";
import selectedAllDoxIcon from "./selectedAllDox.svg";
import selectedBusinessCardTabIcon from "./selectedBusinessCardTab.svg";
import selectedFavouriteIcon from "./selectedFavourite.svg";
import settingIcon from "./settingIcon.svg";
import shareIcon from "./shareIcon.svg";
import bankAccountIcon from './docTypeIcons/bank_account.svg';
import baptismIcon from './docTypeIcons/baptism.svg';
import birthCertificateIcon from './docTypeIcons/birth_certificate.svg';
import bookingHotelIcon from './docTypeIcons/booking.svg';
import carInsuranceIcon from './docTypeIcons/car.svg';
import deathCertificateIcon from './docTypeIcons/death.svg';
import degreeCardIcon from './docTypeIcons/degree.svg';
import divorceCertificateIcon from './docTypeIcons/divorce.svg';
import drivingLicenceDetailIcon from './docTypeIcons/driving_license.svg';
import employmentCardIcon from './docTypeIcons/employment.svg';
import idCardIcon from './docTypeIcons/id.svg';
import insuranceIcon from './docTypeIcons/insurance.svg';
import itineraryIcon from './docTypeIcons/itinerary.svg';
import loyaltyCardDetailIcon from './docTypeIcons/loyalty_card.svg';
import marriageCardIcon from './docTypeIcons/marriage.svg';
import passportIcon from './docTypeIcons/passport.svg';
import photoPassportIcon from './docTypeIcons/photo_passport.svg';
import residencyVisaIcon from './docTypeIcons/visa.svg';
import resumeCoverLetterIcon from './docTypeIcons/resume.svg';
import tenancyContractIcon from './docTypeIcons/tenancy.svg';
import vaccinationIcon from './docTypeIcons/vaccination.svg';
import willIcon from './docTypeIcons/will.svg';
import businessCardBackground from './businessCardBackground.png';

const images = {
  googleMap,
  instagram,
  linkedin,
  twitter,
  whatsapp,
  notificationIcon,
  familyMemberIcon,
  helpCenterIcon,
  logOutIcon,
  selectedAllDoxIcon,
  selectedBusinessCardTabIcon,
  selectedFavouriteIcon,
  settingIcon,
  shareIcon,
  pdfImage,
  businessCardBackground,
};

export const DOC_TYPE_ICONS = {
  bank_account: bankAccountIcon,
  baptism: baptismIcon,
  birth_certificate: birthCertificateIcon,
  booking_hotel: bookingHotelIcon,
  car_insurance: carInsuranceIcon,
  death_certificate: deathCertificateIcon,
  degree_card: degreeCardIcon,
  divorce_certificate: divorceCertificateIcon,
  driving_licence_detail: drivingLicenceDetailIcon,
  employmentCard: employmentCardIcon,
  idCard: idCardIcon,
  insurance: insuranceIcon,
  itinerary: itineraryIcon,
  loyalty_card_detail: loyaltyCardDetailIcon,
  marriageCard: marriageCardIcon,
  passport: passportIcon,
  photo_passport: photoPassportIcon,
  residency_visa: residencyVisaIcon,
  resume_cover_letter: resumeCoverLetterIcon,
  tenancy_contract: tenancyContractIcon,
  vaccination: vaccinationIcon,
  will: willIcon,
}

export default images;
