import axiosinstance from "./axiosinstance";
import EndPoints from "./EndPoints";
import withoutAuthAxios from "./withoutAuthAxios";

// Auth
export const loginService = (data) =>
  withoutAuthAxios.post(EndPoints.login, data);

export const signUpService = (data) =>
  axiosinstance.post(EndPoints.signup, data);

//Logout
export const logoutService = () => axiosinstance.post(EndPoints.logout);

// Check Email Or Mobile is exist or not
export const checkEmailOrMobileIsExistOrNotService = (type, email_mobile) =>
  axiosinstance.post(EndPoints.checkEmailOrMobileIsExistOrNot, {
    type,
    email_mobile,
  });
export const checkValidUserService = (data) =>
  axiosinstance.post(EndPoints.checkValideUser, data);
export const deleteFamilyMemberService = (data) =>
  axiosinstance.post(EndPoints.deleteFamilyMember, data);

// Favourites
export const changeFavouriteOrderService = (orderSequence) =>
  axiosinstance.post(EndPoints.changeOrderOfFavourites, { orderSequence });
export const getShareWithAnyOneLinkService = (data) =>
  axiosinstance.post(EndPoints.shareWithAnyOne, data);
export const updateShareLinkService = (data) =>
  axiosinstance.post(EndPoints.updateShareLink, data);
export const getShareWithPeopleLinkService = (data) =>
  axiosinstance.post(EndPoints.shareWithPeople, data);

export const deleteSharedDocService = (share_docs_no) =>
  axiosinstance.post(EndPoints.deleteSharedDoc, { share_docs_no });
export const deleteShareWithMeService = (share_doc_id) =>
  axiosinstance.post(EndPoints.deleteShareWithMe, { share_doc_id });
export const getUserPersonalInfoService = (token) =>
  axiosinstance.get(`${EndPoints.getUserPersonalInfo}/${token}`);
export const updateUserPersonalInfoService = (data) =>
  axiosinstance.post(EndPoints.updateUserPersonalInfo, data);
export const updateEmailService = (data) =>
  axiosinstance.post(EndPoints.shareEmail, data);
export const shareBusinessCardService = (email) =>
  axiosinstance.post(EndPoints.shareBusinessCard, { email });

export const getUserProfessionalInfoService = (token) =>
  axiosinstance.get(`${EndPoints.getUserProffessionalInfo}/${token}`);
export const updateUserProfessionalInfoService = (data) =>
  axiosinstance.post(EndPoints.updateUserProffessionalInfo, data);

export const getUserBusinessCardInfoService = () =>
  axiosinstance.get(EndPoints.getUserBusinessCardInfo);
export const getUserBusinessCardSettingService = () =>
  axiosinstance.get(EndPoints.getUserBusinessCardSetting);
export const updateUserBusinessCardInfoService = (data) =>
  axiosinstance.post(EndPoints.updateUserBusinessCardInfo, data);
export const updateFavoriteDataListService = (data) =>
  axiosinstance.post(EndPoints.updateFavoriteListData, data);
export const getNotificationFrequencyService = () =>
  axiosinstance.get(EndPoints.getNotificationFrequency);
export const getNotificationManagementService = (token) =>
  axiosinstance.get(`${EndPoints.getNotificationManagement}/${token}`);
export const storeNotificationManagementDataService = (data) =>
  axiosinstance.put(EndPoints.storeNotificaitionManagement, data);
export const deleteAllDocumentDataService = (data) =>
  axiosinstance.post(EndPoints.delete_all_doc, data);

export const getSharedWithMeService = () =>
  axiosinstance.get(EndPoints.getSharedWithMe);
export const getSharedByMeService = () =>
  axiosinstance.get(EndPoints.getSharedByMe);
export const getSharedDocDetailsService = (share_docs_no) =>
  axiosinstance.get(`${EndPoints.getSharedDocDetail}/${share_docs_no}`);
export const saveSharedDocumentService = (data) =>
  axiosinstance.post(EndPoints.saveSharedDocument, data);

export const sendHelpCenterQueryService = (data) =>
  axiosinstance.post(EndPoints.sendHelpCenterQuery, data);

export const deleteAccountService = () =>
  axiosinstance.post(EndPoints.deleteAccount);
export const deleteMyDataService = () =>
  axiosinstance.post(EndPoints.deleteMyData);
export const verifyUserService = (data) =>
  axiosinstance.post(EndPoints.verifyUser, data);
export const changePasswordService = (data) =>
  axiosinstance.post(EndPoints.changePassword, data);
export const getNotificationListService = () =>
  axiosinstance.post(EndPoints.getNotificationList);
export const getInviteFrinedService = () =>
  axiosinstance.get(EndPoints.getInviteFriend);
export const deleteAllNotificationService = () =>
  axiosinstance.post(EndPoints.deleteAllNotification);
export const deleteNotificationService = (notification_id) =>
  axiosinstance.post(EndPoints.deleteNotification, { id: notification_id });
export const readNotificationService = (notification_id) =>
  axiosinstance.post(EndPoints.readNotification, { id: notification_id });
export const readAllNotificationService = () =>
  axiosinstance.post(EndPoints.readAllNotification);
export const getPrivacyPolicyService = () =>
  axiosinstance.get(EndPoints.getPrivacyPolicy);
export const getTermsOfUseService = () =>
  axiosinstance.get(EndPoints.getTermsOfUse);
export const sendMailService = () => axiosinstance.post(EndPoints.sendMail);
export const verifyMailService = (otp) =>
  axiosinstance.post(EndPoints.verifyMail, { otp });
export const verifyEmailService = (data) =>
  axiosinstance.post(EndPoints.verifyMail, data);
export const getDocumentListByIdService = (id) =>
  axiosinstance.get(`${EndPoints.getDocumentListById}/${id}`);
