import React from "react";
import i18n from "../../../i18n/i18n";

const CommonDropDown = ({
  name,
  id,
  data,
  className = "form-control",
  value,
  onChange,
  props,
  style,
  displayKey = "name",
  valueKey = "value",
  disabled = false,
}) => {
  return (
    <select
      style={style}
      name={name}
      id={id}
      className={className}
      value={value ?? ""}
      onChange={onChange}
      {...props}
      disabled={disabled}
    >
      <option value={""}>{i18n.t("select_value")}</option>
      {data.map((item) => {
        return (
          <option key={item?.[valueKey]} value={item?.[valueKey]}>
            {item?.[displayKey]}
          </option>
        );
      })}
    </select>
  );
};

export default CommonDropDown;
