import { useState } from "react";

const useSignUp = () => {
  const [formData, setformData] = useState([]);

  //   On Input Data Change
  const onInputDataChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  return {
    formData,
    onInputDataChange,
  };
};

export default useSignUp;
