import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { allRoutes } from "../../routes/routes";
import useSignUp from "./useSignUp";
import CommonTextInput from "../../components/common/commonTextInput/CommonTextInput";
import CommonDropDown from "../../components/common/commonDropDown/CommonDropDown";
import { useDispatch, useSelector } from "react-redux";
import { getCountryListSelector } from "../../redux/selectors/CommonSelectors";
import CustomToast from "../../toastify/CustomToast";
import i18n from "../../i18n/i18n";
import { EMAIL_CHECK, PASSWORD_CHECK } from "../../constants/constants";
import { GET_COUNTRY_LIST } from "../../redux/actions/CommonActions";
import moment from "moment";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countryList = useSelector(getCountryListSelector);
  const [type, setType] = useState("password");
  const [checked, setChecked] = useState(false);
  const { formData, onInputDataChange } = useSignUp();

  useEffect(() => {
    dispatch(GET_COUNTRY_LIST());
  }, []);

  const onSubmitPress = (termPolicy) => {
    if (!formData?.firstName)
      return CustomToast.error(i18n.t("enter_first_name"));
    if (!formData?.lastName)
      return CustomToast.error(i18n.t("enter_last_name"));
    if (!formData?.email)
      return CustomToast.error(i18n.t("enter_email_address"));
    if (!EMAIL_CHECK.test(formData?.email))
      return CustomToast.error(
        i18n.t("enter_valid_email_address_with_example")
      );
    if (!PASSWORD_CHECK.test(formData?.password))
      return CustomToast.error(i18n.t("password_should_be_validation"));
    if (formData?.password != formData?.confirmpassword)
      return CustomToast.error(i18n.t("password_mismatch"));
    if (!formData?.gender) return CustomToast.error(i18n.t("select_gender"));
    if (!formData?.dob) return CustomToast.error(i18n.t("select_dob"));
    if (!formData?.countryResidence)
      return CustomToast.error(i18n.t("select_country"));
    if (!formData?.nationality)
      return CustomToast.error(i18n.t("select_nationality"));
    if (!formData?.residenceAddress)
      return CustomToast.error(i18n.t("enter_address"));
    if (!termPolicy)
      return CustomToast.error(i18n.t("please_accept_terms_and_conditions"));
    const data = {
      first_name: formData?.firstName.trim() || "",
      middle_name: formData?.middleName.trim() || "",
      last_name: formData?.lastName.trim() || "",
      email: formData?.email?.toLowerCase() || "",
      dob: moment(formData?.dob).format("DD/MM/YYYY") || "",
      password: formData?.password || "",
      c_password: formData?.confirmpassword || "",
      address: formData?.residenceAddress || "",
      gender: formData?.gender,
      residence_address: formData?.residenceAddress || "",
      mobile: "",
      image: "",
      country_code: "",
      country: formData?.countryResidence,
      nationality: formData?.nationality,
      device_token: "xyz",
    };
    navigate(allRoutes.confirmMobile, { state: { data } });
  };

  return (
    <>
      <div className="d-flex flex-column flex-root app-root">
        <div className="main-body-cls">
          <section>
            <div className="container custom-container">
              <div className="row justify-content-center mt-5">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card border-0">
                    <div className="card-title text-start border-bottom-0">
                      <h2 className="p-3">SignUp</h2>
                    </div>
                    <div className="card-body">
                      <form className="form-custm-float-lb">
                        <div className="mb-4 position-relative">
                          <CommonTextInput
                            type="text"
                            className="form-control"
                            id="first_name"
                            name="firstName"
                            onChange={onInputDataChange}
                            value={formData.firstName}
                            placeholder="Enter First Name"
                          />
                          <label
                            htmlFor="first_name"
                            className="form-label flt-label"
                          >
                            First Name
                          </label>
                        </div>
                        <div className="mb-4 position-relative">
                          <CommonTextInput
                            type="text"
                            className="form-control"
                            id="middle_name"
                            name="middleName"
                            onChange={onInputDataChange}
                            value={formData.middleName}
                            placeholder="Enter Middle Name"
                          />
                          <label
                            htmlFor="middle_name"
                            className="form-label flt-label"
                          >
                            Middle Name
                          </label>
                        </div>
                        <div className="mb-4 position-relative">
                          <CommonTextInput
                            type="text"
                            className="form-control"
                            id="last_name"
                            name="lastName"
                            onChange={onInputDataChange}
                            value={formData.lastName}
                            placeholder="Enter Last Name"
                          />
                          <label
                            htmlFor="last_name"
                            className="form-label flt-label"
                          >
                            Last Name
                          </label>
                        </div>
                        <div className="mb-4 position-relative">
                          <CommonTextInput
                            type="email"
                            className="form-control"
                            id="email_id"
                            name="email"
                            onChange={onInputDataChange}
                            value={formData.email}
                            placeholder="Enter Email"
                          />
                          <label
                            htmlFor="email_id"
                            className="form-label flt-label"
                          >
                            Email ID
                          </label>
                        </div>
                        <div className="mb-4 position-relative">
                          <CommonTextInput
                            type={type}
                            className="form-control pe-5"
                            id="enter_pass"
                            name="password"
                            onChange={onInputDataChange}
                            value={formData.password}
                            placeholder="Enter Password"
                          />
                          <i
                            className={`fa ${
                              type == "password" ? "fa-eye-slash" : "fa-eye"
                            } showps`}
                            id="showpp"
                            onClick={() =>
                              setType(type == "password" ? "text" : "password")
                            }
                          />
                          <label
                            htmlFor="enter_pass"
                            className="form-label flt-label"
                          >
                            Password
                          </label>
                          <span className="mt-1 d-block pass-instuction">
                            Password should be min 8 characters
                          </span>
                        </div>
                        <div className="mb-2 position-relative">
                          <input
                            type={type}
                            className={"form-control pe-5"}
                            id={"password"}
                            name={"confirmpassword"}
                            onChange={onInputDataChange}
                            value={formData?.cPassword}
                            placeholder={"Confirm Password"}
                          />
                          <i
                            className={`fa ${
                              type == "password" ? "fa-eye-slash" : "fa-eye"
                            } showps`}
                            id="showp"
                            onClick={() =>
                              setType(type == "password" ? "text" : "password")
                            }
                          />
                          <label
                            htmlFor="password"
                            className="form-label flt-label"
                          >
                            Confirm Password
                          </label>
                        </div>
                        <div className="gender-sec-fild mb-4">
                          <div className="mb-3 mt-3">
                            <h3>Gender</h3>
                          </div>
                          <div className="hoby-box-rdb-custom d-flex align-items-center">
                            <div className="mb-1 d-flex align-items-center">
                              <CommonTextInput
                                type="radio"
                                name="gender"
                                id="male"
                                onChange={onInputDataChange}
                                value={"male"}
                                checked={formData?.gender == "male"}
                                className="d-none"
                              />
                              <label htmlFor="male" className="form-label mb-0">
                                Male
                              </label>
                            </div>
                            <div className="mb-1 d-flex align-items-center ms-3">
                              <CommonTextInput
                                type="radio"
                                name="gender"
                                id="female"
                                onChange={onInputDataChange}
                                value={"female"}
                                checked={formData?.gender == "female"}
                                className="d-none"
                              />
                              <label
                                htmlFor="female"
                                className="form-label mb-0"
                              >
                                Female
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-4 position-relative">
                          <input
                            type="date"
                            className="form-control"
                            name="dob"
                            onChange={onInputDataChange}
                            value={formData.birthDate}
                            id="dob"
                          />
                          <label htmlFor="dob" className="form-label flt-label">
                            DOB
                          </label>
                        </div>
                        <div className="mb-4 position-relative">
                          <CommonDropDown
                            name="nationality"
                            id="nationality"
                            displayKey="country_name"
                            onChange={onInputDataChange}
                            value={formData.nationality}
                            data={countryList}
                          />
                          <label
                            htmlFor="nationality"
                            className="form-label flt-label"
                          >
                            Nationality
                          </label>
                        </div>
                        <div className="mb-4 position-relative">
                          <CommonDropDown
                            name="countryResidence"
                            id="con_resident"
                            displayKey="country_name"
                            onChange={onInputDataChange}
                            value={formData.countryResidence}
                            data={countryList}
                          />
                          <label
                            htmlFor="con_resident"
                            className="form-label flt-label"
                          >
                            Country of Residence
                          </label>
                        </div>
                        <div className="mb-4 position-relative">
                          <CommonTextInput
                            type="text"
                            className="form-control"
                            id="city_residence"
                            name="cityResidence"
                            onChange={onInputDataChange}
                            value={formData.cityResidence}
                            placeholder="City of Residence"
                          />
                          <label
                            htmlFor="city_resident"
                            className="form-label flt-label"
                          >
                            City of Residence
                          </label>
                        </div>
                        <div className="mb-4 position-relative">
                          <input
                            type="text"
                            className="form-control"
                            id="residental_add"
                            name="residenceAddress"
                            placeholder="Enter Address"
                            onChange={onInputDataChange}
                            value={formData.residentalAddress}
                          />
                          <label
                            htmlFor="residental_add"
                            className="form-label flt-label"
                          >
                            Residental Address
                          </label>
                        </div>
                        <div className="term_service-check md-4">
                          <CommonTextInput
                            type="checkbox"
                            className="form-control"
                            id="term-policy"
                            value={checked}
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                            style={{ accentColor: "#4298B5" }}
                          />
                          <div className="text-center">
                            <label className="d-block" htmlFor="term-policy">
                              By creating an account you agree to our
                            </label>
                            <Link to="javascript:;" className="text-danger">
                              Terms Of Service &amp; Privacy Policy
                            </Link>
                          </div>
                        </div>
                        <div className="md-4 mt-5 text-center">
                          <button
                            type="button"
                            onClick={() => onSubmitPress(checked)}
                            className="btn btn-info button-drk-info"
                          >
                            Create Account
                          </button>
                        </div>
                        <div className="md-4 text-center">
                          <p className="dnt-ac-tx">
                            {" "}
                            Already have an account ?{" "}
                            <Link to={allRoutes.confirmMobile}>LogIn</Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default SignUp;
