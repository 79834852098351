import _ from "lodash";
import i18n from "../i18n/i18n";
import { typeViseFavoritCard } from "./imageData";

export const BASE_URL = "https://admin.doxboxapp.com/api/";
export const DevConfig = {
  ENABLE_CONSOLE_LOGS: true,
};

const url = "https://admin.doxboxapp.com";
export const IMAGE_URL = `${url}/`;

export const PUBLIC_PROFILE_LINK = `${BASE_URL}profile/`;
export const NUMBER_OF_FAVOURITES_LIMIT = 15;
export const EXPIRE_LINK_DAYS = 30;
export const GOOGLE_API_KEY = "AIzaSyAEhJ3p42VJiGWMZcLBxiiNH2TOCkUEWZo"; // for use of google Map API
export const EMAIL_CHECK =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MOBILE_CHECK = /^\(?([1-9]{1})\)?[-. ]?([0-9]{6,9})$/;
export const PASSWORD_CHECK =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
export const GLOBAL_DOC_SHARE_LINK = (isPrivate, share_docs_no) =>
  `${BASE_URL}${isPrivate == 1 ? "plink" : "pblink"}/${share_docs_no}`;

export const getSocialLinks = (type, username) => {
  if (username) {
    switch (type) {
      case "linkedin":
        return `https://www.linkedin.com/in/${username}/`;
      case "instagram":
        return `https://www.instagram.com/${username}/`;
      case "twitter":
        return `https://twitter.com/${username}/`;
      default:
        return "";
    }
  } else {
    return "";
  }
};

export const getDocumentTypeLocaleName = (item) => {
  const translationKey = getDocTypeTranslationKey();
  return item?.[translationKey];
};

export const getDocTypeTranslationKey = () => {
  const locale = i18n.language;
  switch (locale) {
    case "en":
      return "name";
    case "ar":
      return "arebic_name";
    case "fr":
      return "french_name";
    case "de":
      return "dutch_name";
    case "es":
      return "spanish_name";
    default:
      return "name";
  }
};

export const convertMetaDataToKeyPairs = (metaData) => {
  const data = {};
  metaData?.map((infoItem) => {
    data[infoItem?.meta_key] = infoItem;
  });
  return data;
};

export const getMetaDataValue = (data, meta_key) =>
  data?.[meta_key]?.in_or_on_card == 1
    ? data?.[meta_key]?.meta_value ?? ""
    : null;

export const getServerImageUrl = (path) => (path ? IMAGE_URL + path : "");
export const getDocumentTypeSvgIcon = (id) => typeViseFavoritCard[id]?.icon

export const escapeRegExp = (str) => {
  if (!_.isString(str)) return "";
  return str.replace(/[-[\]\\/{}()*+?.^$|]/g, "\\$&");
};

export const getGlobalSearchData = (data = [], field = [], searchString) => {
  const searchData = [];
  const searchStr = escapeRegExp(searchString).replace(/ /g, '')
  if (searchStr !== '') {
      data.map((item) => {
          let isSearch = false;
          field.map((key) => {
              if (!isSearch
                  && item?.[key]
                      ?.toLowerCase()
                      ?.toString()
                      ?.replace(/ /g, '')
                      ?.match(searchStr?.toLowerCase()?.toString())) {
                  isSearch = true;
              }
              return true;
          })
          if (isSearch) searchData.push(item);
          return true;
      })
  }
  return searchData;
}
