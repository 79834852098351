import React, { useState } from "react";
import CommonTextInput from "../../components/common/commonTextInput/CommonTextInput";
import CustomToast from "../../toastify/CustomToast";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../module/firebase";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [expandForm, setExpandForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [user, setUser] = useState(null);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + mobileNumber;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setExpandForm(true);
        CustomToast.success("OTP sended successfully!");
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(OTP)
      .then(async (res) => {
        setUser(res.user);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  return (
    <>
      <div className="d-flex flex-column flex-root app-root">
        <div className="main-body-cls">
          <section className="login-page">
            <div className="container custom-container">
              <div className="row justify-content-center mt-5">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card border-0">
                    <div className="card-title text-start border-bottom-0">
                      <h2 className="p-3">Forgot Password</h2>
                    </div>
                    <div className="card-body">
                      <label htmlFor="userId" className="form-label flt-label">
                        Email ID
                      </label>
                      <div className="mb-4 position-relative">
                        <CommonTextInput
                          name="email"
                          onChange={({ target }) => setEmail(target?.value)}
                          value={email}
                          className="form-control"
                          id="userId"
                          placeholder="Enter Email"
                        />
                      </div>
                      <div className="mb-4 position-relative">
                        <PhoneInput
                          country={"in"}
                          inputStyle={{ width: 380, height: 40 }}
                          value={mobileNumber}
                          onChange={(phone) => setMobileNumber(phone)}
                        />
                      </div>
                      {expandForm && (
                        <div className="mb-1 position-relative">
                          <label
                            htmlFor="password"
                            className="form-label flt-label"
                          >
                            OTP
                          </label>
                          <CommonTextInput
                            name="otp"
                            value={OTP}
                            className="form-control pe-5"
                            id="otp"
                            maxLength={6}
                            placeholder="Enter OTP"
                            onChange={({ target }) => setOTP(target?.value)}
                          />
                        </div>
                      )}

                      <div
                        style={{ marginTop: 20 }}
                        className="md-4 text-center"
                      >
                        <button
                          type="submit"
                          onClick={expandForm ? onOTPVerify : onSignup}
                          className="btn btn-info button-drk-info"
                        >
                          {expandForm ? "Verify" : "Send"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
