import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/actions/AuthActions";
import CustomToast from "../../toastify/CustomToast";
import { SET_COUNTRY_INFO } from "../../redux/reducers/commonReducer";
import i18n from "../../i18n/i18n";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../routes/routes";

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setformData] = useState([]);

  const getGeoInfo = async () => {
    const url = "https://ipinfo.io/json?token=6da00a4bbb4340";
    const response = await fetch(url);
    const data = await response.json();
    dispatch({ type: SET_COUNTRY_INFO, payload: data });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  //   On Input Data Change
  const onInputDataChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // On Login Press
  const onLoginHandler = (e) => {
    e.preventDefault();
    if (!isEmailValid(formData.email))
      return CustomToast.error("Enter a valid email address");
    if (!formData.email) return CustomToast.error("Enter email");
    if (!formData.password) return CustomToast.error("Enter password");

    dispatch(loginAction(formData)).then((res) => {
      if (res?.status) {
        CustomToast.success(i18n.t("login_successFully"));
      } else {
        CustomToast.error(res?.message);
      }
    });
  };

  return {
    formData,
    onInputDataChange,
    onLoginHandler,
  };
};

export default useLogin;
