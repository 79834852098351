import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUserIsLoggedInSelector } from "../redux/selectors/AuthSelectors";
import { allRoutes } from "../routes/routes";

const ProtectedRoutes = ({ type = "after_login" }) => {
  const userIsLoggedIn = useSelector(getUserIsLoggedInSelector);

  return type === "after_login" ? (
    userIsLoggedIn ? (
      <Outlet />
    ) : (
      <Navigate to={allRoutes.login} />
    )
  ) : userIsLoggedIn ? (
    <Navigate to={allRoutes.dashboard} />
  ) : (
    <Outlet />
  );
};

export default ProtectedRoutes;
