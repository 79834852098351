export const getThemeSelector = (state) => state?.common?.theme;
export const getAllContactsSelector = (state) => state?.common?.allContacts;
export const getNotificationListSelector = (state) =>
  state?.common?.notificationList;
export const getFrequnecyListSelector = (state) => state?.common?.frequencyList;
export const getNotificationManagementSelector = (state) =>
  state?.common?.notificationManagementList;
export const getInviteFriendDescSelector = (state) =>
  state?.common?.inviteFriendDesc;
export const getOtpDataSelector = (state) => state?.common?.otpData;
export const getUnreadNotificationListSelector = (state) =>
  state?.common?.notificationList?.filter((item) => !item?.is_read)?.length;
export const getUserPersonalDataSelelctor = (state) =>
  state?.auth?.userPersonalData;
export const getUserProfessionalDataSelelctor = (state) =>
  state?.auth?.userProfessionalData;
export const getUserBusinessCardDataSelelctor = (state) =>
  state?.auth?.userBusinessCard;
export const getUserBusinessCardSettingSelelctor = (state) =>
  state?.auth?.userBusinessCardSetting;
export const getIsBiometricsEnabledSelector = (state) =>
  state?.common?.enabledBioMetrics ?? false;
export const getCountryListSelector = (state) =>
  state?.common?.countryList ?? [];
export const getDefaultDownloadDirectorySelector = (state) =>
  state?.common?.defaultDownloadDirectory ?? null;
export const getSharedByMeDataSelector = (state) =>
  state?.common?.sharedByMe ?? null;
export const getSharedWithMeDataSelector = (state) =>
  state?.common?.sharedWithMe ?? null;
export const getSharedDocDetailsSelector = (state) =>
  state?.common?.shareDocsDetails ?? {};
export const getSelectedDocumentType = (state) =>
  state.common.documentType;
export const getDocumentListByFamilySelector = (state) =>
  state.common.documentListByFamily ?? [];
export const getDocumentListByDocTypeSelector = (state) =>
  state.common.documentListByDocType ?? [];
export const getFavouriteListSelector = (state) =>
  state.common.favoriteList ?? [];
export const getCountryInfoSelector = (state) =>
  state.common.countryInfo ?? null;
export const getFamilyMembersListSelector = (state) =>
  state?.common?.familyMembersList ?? [];
export const getDocumentListSelector = (state) =>
  state?.common?.documentList ?? [];
export const getDocumentFieldListSelector = (state) =>
  state?.common?.documentFieldsList ?? [];
export const getRelationList = (state) => state?.common?.relationList ?? [];
export const getBirthdayYear = (state) => state?.common?.birthDayYear ?? null;
export const getCurrentRoute = (state) => state?.common?.bottomTabRoute ?? null;
