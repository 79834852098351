import React from "react";
import { useRoutes } from "react-router-dom";
import RoutesPath from "../../routes/routes";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import CommonSidebar from "../common/commonSidebar/CommonSidebar";

const RouterOutlet = () => {
  const routes = useRoutes(RoutesPath);
  return routes;
};

const Layout = () => {
  return (
    <>
      <Header />
      <CommonSidebar>
        <RouterOutlet />
      </CommonSidebar>
      <Footer />
    </>
  );
};

export default Layout;
