import React from "react";
import { Link } from "react-router-dom";
import { allRoutes } from "../../../routes/routes";
import CommonTextInput from "../../common/commonTextInput/CommonTextInput";
import images from "../../../assets/images/images";

const header = () => {
  return (
    <div>
      <header className="login-header-main pt-0 pb-0">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-7 text-end d-flex align-items-center">
              <Link to={allRoutes.dashboard}>
                <img
                  src="images/doxbox-logo.png"
                  alt=""
                  className="ms-2"
                  width={170}
                  height={30}
                />
              </Link>
            </div>
            <div className="col-lg-5 p-2 d-flex align-items-center justify-content-end">
              {/* <div className="header-right-serch position-relative me-3">
                <CommonTextInput
                  type="text"
                  name=""
                  id=""
                  placeholder="Search"
                />
                <i className="bi bi-search" />
              </div> */}
              {/* <span className="header-round-ico me-3">
                <i className="bi bi-bell-fill" />
              </span> */}
              <Link to={allRoutes.settings}>
                <img src={images.settingIcon} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default header;
