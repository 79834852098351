import { Link } from "react-router-dom";
import { allRoutes } from "../../../routes/routes";
import useCommonSidebar from "./useCommonSidebar";
import { useSelector } from "react-redux";
import { getUserDataSelelctor } from "../../../redux/selectors/AuthSelectors";
import { getServerImageUrl } from "../../../constants/constants";
import { getUserPersonalDataSelelctor } from "../../../redux/selectors/CommonSelectors";
import images from "../../../assets/images/images";

const CommonSidebar = ({ children }) => {
  const { onLogOutPress } = useCommonSidebar();
  const userData = useSelector(getUserDataSelelctor);
  const userPersonalData = useSelector(getUserPersonalDataSelelctor);

  return (
    <div className="main-body-cls">
      <div className="container-fluid">
        <div className="row flex-wrap">
          <div className="doxbox-side-bar col-md-12 col-xl-3 px-sm-2 px-0">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-0 pt-2 text-white min-vh-100">
              <div className="side-panl-info">
                <Link to={allRoutes.profile} className="text-white">
                  <div className="user-details-sidebar">
                    {userPersonalData?.image_url ? (
                      <img
                        src={getServerImageUrl(userPersonalData?.image_url)}
                        alt=""
                        width={60}
                        height={60}
                        style={{ borderRadius: 30 }}
                      />
                    ) : (
                      <span className="usr-nm-tx">{`${
                        userData?.first_name?.[0] ?? ""
                      }${userData?.last_name?.[0] ?? ""}`}</span>
                    )}
                    <h3>{`${userData?.first_name ?? ""} ${
                      userData?.last_name ?? ""
                    }`}</h3>
                    <p className="mt-2">
                      {userData?.email}
                      <span className="ps-1 ms-1 border-start">
                        {`ID:${userData?.registration_no}`}
                      </span>
                    </p>
                  </div>
                </Link>
                <h2 className="mb-4">Welcome to DoxBox</h2>
              </div>
              <ul
                className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
                id="menu"
              >
                <li className="nav-item active">
                  <Link
                    to={allRoutes.dashboard}
                    className="nav-link align-middle px-0 active"
                  >
                    <i>
                      <img src={images.selectedAllDoxIcon} alt="" width={21} />
                    </i>
                    <span className="ms-2 d-sm-inline">All Dox</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={allRoutes.favorites}
                    className="nav-link px-0 align-middle"
                  >
                    <i>
                      <img src={images.selectedFavouriteIcon} alt="" width={21} />
                    </i>
                    <span className="ms-2 d-sm-inline">Favorites</span>{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to={allRoutes.businessCard}
                    className="nav-link px-0 align-middle"
                  >
                    <i>
                      <img src={images.selectedBusinessCardTabIcon} alt="" width={21} />
                    </i>
                    <span className="ms-2 d-sm-inline">Business card</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={allRoutes.familyMember}
                    className="nav-link px-0 align-middle "
                  >
                    <i>
                      <img src={images.familyMemberIcon} alt="" width={21} />
                    </i>
                    <span className="ms-2 d-sm-inline">Family Members</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={allRoutes.shareManagement}
                    className="nav-link px-0 align-middle"
                  >
                    <i>
                      <img src={images.shareIcon} alt="" width={21} />
                    </i>
                    <span className="ms-2 d-sm-inline">Share Management</span>{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to={allRoutes.helpCenter}
                    className="nav-link px-0 align-middle"
                  >
                    <i>
                      <img src={images.helpCenterIcon} alt="" width={22} />
                    </i>
                    <span className="ms-2 d-sm-inline">Help Center</span>{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"#"}
                    onClick={onLogOutPress}
                    className="nav-link px-0 align-middle"
                  >
                    <i>
                      <img src={images.logOutIcon} alt="" width={22} />
                    </i>
                    <span className="ms-2 d-sm-inline">Logout</span>{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col body-content-main-clr">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CommonSidebar;
