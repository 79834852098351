import moment from "moment";

export const jsonToFormData = (jsonObj) =>
  Object.entries(jsonObj).reduce(
    (current, item) => (current.append(...item), current),
    new FormData()
  );

export const sendDataToReducer = (dispatch, type = null, payload = null) => {
  if (type) dispatch(type(payload));
};

export const formattedAmount = (amount = 0) => {
  const numericAmount = Number(amount);
  if (isNaN(numericAmount)) {
    return amount;
  }
  const fractionDigits = numericAmount % 1 === 0 ? 0 : 2;
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    minimumIntegerDigits: 1,
    useGrouping: true,
  });
  return `R ${formatter.format(numericAmount)}`;
};

export const getValidDate = (
  date = null,
  format = "MM-DD-YYYY",
  getDateObject = false
) => {
  const validDate = date;
  if (getDateObject) return new Date(getValidDate(validDate, "YYYY/MM/DD"));
  if (moment(validDate).isValid()) {
    if (getDateObject) return validDate;
    return moment(validDate).format(format ?? "YYYY/MM/DD");
  }
  return null;
};
