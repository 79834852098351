import { combineReducers } from "@reduxjs/toolkit";
import { RESET_STORE } from "../types/ReduxTypes";
import AppDefaultReducer from "../defaults/AppDefaultReducer";
import AuthReducer from "./AuthReducer";
import CommonReducer from "./commonReducer";

const appReducer = combineReducers({
  auth: AuthReducer,
  common: CommonReducer,
});

export default function rootReducer(state, action) {
  let finalState = appReducer(state, action);
  if (action.type === RESET_STORE) {
    const defaultReducer = {
      ...AppDefaultReducer,
    };
    finalState = defaultReducer;
  }
  return finalState;
}
