import {
  checkEmailOrMobileIsExistOrNotService,
  getUserBusinessCardInfoService,
  getUserPersonalInfoService,
  getUserProfessionalInfoService,
  loginService,
  signUpService,
  updateEmailService,
  updateUserPersonalInfoService,
  updateUserProfessionalInfoService,
} from "../../services/Services";
import {
  RESET_USER_DATA,
  SET_APP_LANGUAGE,
  SET_TOKEN,
  SET_USER_DATA,
  UPDATE_BUSINESS_CARD_DATA,
  UPDATE_PERSONAL_DATA,
  UPDATE_PROFESSIONAL_DATA,
} from "../reducers/AuthReducer";
import { jsonToFormData, sendDataToReducer } from "../../helpers/CommonHelpers";
import CustomToast from "../../toastify/CustomToast";
import {
  getAppLanguageSelector,
  getAuthToken,
  getUserDataSelelctor,
} from "../selectors/AuthSelectors";
import i18n from "../../i18n/i18n";
import { RESET_DATA } from "../reducers/commonReducer";

// Login
export const loginAction = (formData) => (dispatch) =>
  new Promise((resolve, reject) => {
    loginService({
      email: formData?.email,
      password: formData?.password,
      device_token: "12345",
    })
      .then((res) => {
        sendDataToReducer(dispatch, SET_USER_DATA, res?.data);
        sendDataToReducer(dispatch, SET_TOKEN, res?.token);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });

// Signup
export const signUpAction = (formData) => (dispatch) =>
  new Promise((resolve, reject) => {
    signUpService(formData)
      .then((res) => {
        if (res?.status) {
          sendDataToReducer(dispatch, SET_USER_DATA, res?.data);
          sendDataToReducer(dispatch, SET_TOKEN, res?.token);
        }
        resolve(res);
      })
      .catch((error) => {
        console.error("dsvdsvsdv", error);
        reject(error);
      });
  });

// Logout
export const logoutAction =
  (forceLogout = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      sendDataToReducer(dispatch, SET_TOKEN, "");
      dispatch(RESET_USER_DATA());
      dispatch(RESET_DATA())
      // if (!forceLogout) logoutService();
      resolve(true);
    });

export const getPersonalProfileDataAction = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const token = getAuthToken(state);
    getUserPersonalInfoService(token)
      .then((res) => {
        sendDataToReducer(dispatch, UPDATE_PERSONAL_DATA, res?.data);
        resolve(res?.data);
      })
      .catch((error) => {
        reject({ error });
      });
  });

// Get Business Card Data
export const getBusinessCardDataAction = () => (dispatch) =>
  new Promise((resolve, reject) => {
    getUserBusinessCardInfoService()
      .then((res) => {
        sendDataToReducer(dispatch, UPDATE_BUSINESS_CARD_DATA, res?.data);
        resolve(res?.data);
      })
      .catch((error) => {
        reject({ error });
      });
  });

// Update Personal User Info
export const updatePersonalInfoAction = (data) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const userData = getUserDataSelelctor(state);
    data.user_id = userData?.id;
    const formData = jsonToFormData(data);
    updateUserPersonalInfoService(formData)
      .then((res) => {
        if (res?.status) {
          dispatch(getPersonalProfileDataAction());
          dispatch(getBusinessCardDataAction());
          resolve(res);
        } else {
          CustomToast.error(i18n.t("email_is_already_exist"));
        }
      })
      .catch((error) => {
        const errorResponse = error?.response?.data?.error;
        CustomToast.success(
          errorResponse?.[Object.keys(errorResponse)?.[0]]?.[0]
        );
        reject({ error });
      });
  });

// Update User Email
export const updateUserEmailAction = (data) => () =>
  new Promise((resolve, reject) => {
    const formData = jsonToFormData(data);
    updateEmailService(formData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        CustomToast.error(i18n.t("something_went_wrong"));
        reject(e);
      });
  });
// Update User Email
export const checkEmailOrMobileIsExistOrNotAction = (type, data) => () =>
  new Promise((resolve, reject) => {
    checkEmailOrMobileIsExistOrNotService(type, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        CustomToast.error(i18n.t("something_went_wrong"));
        reject(e);
      });
  });

// Get Proffessional Profile Data
export const getProfessionalProfileDataAction = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const token = getAuthToken(state);
    getUserProfessionalInfoService(token)
      .then((res) => {
        sendDataToReducer(dispatch, UPDATE_PROFESSIONAL_DATA, res?.data);
        resolve(res?.data);
      })
      .catch((error) => {
        reject({ error });
      });
  });

// Update Professional User Info
export const updateProfessionalInfoAction = (data) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const userData = getUserDataSelelctor(state);
    data.user_id = userData?.id;
    updateUserProfessionalInfoService(jsonToFormData(data))
      .then((res) => {
        dispatch(getProfessionalProfileDataAction());
        dispatch(getBusinessCardDataAction());
        resolve(res);
      })
      .catch((error) => {
        const errorResponse = error?.response?.data?.error;
        CustomToast.error(
          errorResponse?.[Object.keys(errorResponse)?.[0]]?.[0]
        );
        reject({ error });
      });
  });

export const changeAppLanguageAction =
  (changeToLanguage, fromRestart = false) =>
  (dispatch, getState) =>
    new Promise((resolve) => {
      const state = getState();
      const savedLanguage = getAppLanguageSelector(state);
      let appLanguage = changeToLanguage;
      if (fromRestart) appLanguage = savedLanguage;
      sendDataToReducer(dispatch, SET_APP_LANGUAGE, appLanguage);
      i18n.changeLanguage(appLanguage);
      setTimeout(() => {
        if (appLanguage !== savedLanguage) {
          // I18nManager.forceRTL(['ar', 'he', 'fa'].includes(appLanguage));
          if (
            ["ar", "es", "he", "fa", "en", "de"].includes(savedLanguage) ||
            ["ar", "es", "he", "fa", "en", "de"].includes(appLanguage)
          )
            window.location.reload();
          resolve(appLanguage);
        } else {
          resolve(appLanguage);
        }
      }, 500);
    });
