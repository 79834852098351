import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container custom-container  border-top">
      <div className="row mt-5">
        <div className="col-lg-10 mx-auto">
          <div className="row">
            <div className="col-lg-2 col-sm-6 mb-3">
              <div className="footr-colm-bx">
                <h5>Dropbox</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link href="#">Install</Link>
                  </li>
                  <li>
                    <Link href="#">Mobile</Link>
                  </li>
                  <li>
                    <Link href="#">Pricing</Link>
                  </li>
                  <li>
                    <Link href="#">Business</Link>
                  </li>
                  <li>
                    <Link href="#">Enterprise</Link>
                  </li>
                  <li>
                    <Link href="#">Features</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6 mb-3">
              <div className="footr-colm-bx">
                <h5>About Us </h5>
                <ul className="list-unstyled">
                  <li>
                    <Link href="#">Dropbox Blog</Link>
                  </li>
                  <li>
                    <Link href="#">About</Link>
                  </li>
                  <li>
                    <Link href="#">Branding</Link>
                  </li>
                  <li>
                    <Link href="#">News</Link>
                  </li>
                  <li>
                    <Link href="#">Jobs</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-3">
              <div className="footr-colm-bx">
                <h5>Support</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link href="#">Help Center</Link>
                  </li>
                  <li>
                    <Link href="#">Contact Us</Link>
                  </li>
                  <li>
                    <Link href="#">Copyright</Link>
                  </li>
                  <li>
                    <Link href="#">Cookie Policy</Link>
                  </li>
                  <li>
                    <Link href="#">Cookies &amp; CCPA Preferences</Link>
                  </li>
                  <li>
                    <Link href="#">Privacy &amp; Terms</Link>
                  </li>
                  <li>
                    <Link href="#">Sitemap</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6 mb-3">
              <div className="footr-colm-bx">
                <h5>Community</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link href="#">Referrals</Link>
                  </li>
                  <li>
                    <Link href="#">Forum</Link>
                  </li>
                  <li>
                    <Link href="#">Twitter</Link>
                  </li>
                  <li>
                    <Link href="#">Facebook</Link>
                  </li>
                  <li>
                    <Link href="#">Developers</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-3">
              <div className="footr-colm-bx">
                <ul className="list-unstyled">
                  <li>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle bg-white text-dark border-0 pt-0"
                        type="button"
                        id="languagef"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa fa-globe" aria-hidden="true" /> English
                        (United States)
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="languagef">
                        <li>
                          <Link className="dropdown-item" href="#">
                            English
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" href="#">
                            Spanish
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
