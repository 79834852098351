import axios from "axios";
import { BASE_URL, DevConfig } from "../constants/constants";

const withoutAuthAxios = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

withoutAuthAxios.interceptors.request.use((config) => {
  if (DevConfig.ENABLE_CONSOLE_LOGS) return config;
});

withoutAuthAxios.interceptors.response.use(
  (res) => {
    if (DevConfig.ENABLE_CONSOLE_LOGS) return Promise.resolve(res?.data);
  },
  (error) => {
    if (DevConfig.ENABLE_CONSOLE_LOGS) return Promise.reject(error?.response);
  }
);

export default withoutAuthAxios;
