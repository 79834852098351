import React, { useState } from "react";
import CommonTextInput from "../../components/common/commonTextInput/CommonTextInput";
import CustomToast from "../../toastify/CustomToast";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import i18n from "../../i18n/i18n";
import { useDispatch } from "react-redux";
import {
  checkEmailOrMobileIsExistOrNotAction,
  signUpAction,
} from "../../redux/actions/AuthActions";
import { allRoutes } from "../../routes/routes";

const ConfirmMobile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const previousData = location?.state;
  const [screenData] = useState(previousData?.data);
  const navigate = useNavigate();
  const [expandForm, setExpandForm] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [selCountryCode, setSelCountryCode] = useState("+91");
  const [selCountryExpectedLength, setSelCountryExpectedLength] = useState(0);

  // function onCaptchVerify() {
  //   if (!window.recaptchaVerifier) {
  //     window.recaptchaVerifier = new RecaptchaVerifier(
  //       "recaptcha-container",
  //       {
  //         size: "invisible",
  //         callback: (response) => {
  //           onSignup();
  //         },
  //         "expired-callback": () => {},
  //       },
  //       auth
  //     );
  //   }
  // }

  function onSignup() {
    if (mobileNumber?.trim()?.length == 0) {
      CustomToast.error(i18n.t("enter_mobile_number"));
    } else if (selCountryExpectedLength !== mobileNumber?.length) {
      CustomToast.error(i18n.t("enter_valid_mobile"));
    } else {
      setExpandForm(true);
    }
    // setLoading(true);
    // onCaptchVerify();

    // const appVerifier = window.recaptchaVerifier;

    // const formatPh = "+" + mobileNumber;

    // signInWithPhoneNumber(auth, formatPh, appVerifier)
    //   .then((confirmationResult) => {
    //     window.confirmationResult = confirmationResult;
    //     setLoading(false);
    //     setExpandForm(true);
    //     CustomToast.success("OTP sended successfully!");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //   });
  }

  function onOTPVerify() {
    dispatch(
      checkEmailOrMobileIsExistOrNotAction(
        "email",
        screenData?.email?.toLowerCase()
      )
    )
      .then(async (res) => {
        if (res?.status) CustomToast.error(res?.message);
        else if (OTP?.trim()?.length == 0) {
          CustomToast.error(i18n.t("please_enter_otp"));
        } else if (OTP != "123456") CustomToast.error(i18n.t("invalid_otp"));
        else {
          const regex = new RegExp(`^\\+${selCountryCode}`);
          const mobile = "+" + mobileNumber;
          const updatedMobile = mobile.replace(regex, "");
          const data = {
            first_name: screenData?.first_name.trim() || "",
            middle_name: screenData?.middle_name.trim() || "",
            last_name: screenData?.last_name.trim() || "",
            email: screenData?.email?.toLowerCase() || "",
            dob: screenData?.dob || "",
            password: screenData?.password || "",
            c_password: screenData?.c_password || "",
            address: screenData?.address || "",
            gender: screenData?.gender,
            residence_address: screenData?.residence_address || "",
            mobile: updatedMobile,
            image: "",
            country_code: `+${selCountryCode}` ?? "",
            country: screenData?.country,
            nationality: screenData?.nationality,
            device_token: "xyz",
          };
          dispatch(signUpAction(data))
            .then((response) => {
              if (response?.status) navigate(allRoutes.dashboard);
            })
            .catch((e) => {
              CustomToast.error(e?.message);
            });
        }
      })
      .catch(() => {
        CustomToast.error(i18n.t("something_went_wrong"));
      });
    // setLoading(true);
    // window.confirmationResult
    //   .confirm(OTP)
    //   .then(async (res) => {
    //     console.log("res", res);
    //     setUser(res.user);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //     setLoading(false);
    //   });
  }

  return (
    <>
      <div className="d-flex flex-column flex-root app-root">
        <div className="main-body-cls">
          <section className="login-page">
            <div className="container custom-container">
              <div className="row justify-content-center mt-5">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card border-0">
                    <div className="card-title text-start border-bottom-0">
                      <h2 className="p-3">
                        {expandForm
                          ? i18n.t("otp_verification")
                          : i18n.t("confirm_mobile")}
                      </h2>
                    </div>
                    <div className="card-body">
                      {!expandForm && (
                        <div className="mb-4 position-relative">
                          <PhoneInput
                            country={"in"}
                            inputStyle={{ width: 380, height: 40 }}
                            value={mobileNumber}
                            onChange={(inputPhone, countryData) => {
                              setMobileNumber(inputPhone);
                              setSelCountryExpectedLength(
                                countryData?.format?.replace(/[()\s+\-]/g, "")
                                  ?.length
                              );
                              setSelCountryCode(countryData?.dialCode);
                            }}
                            countryCodeEditable={false}
                            enableSearch={true}
                          />
                        </div>
                      )}
                      {expandForm && (
                        <div className="mb-1 position-relative">
                          <label
                            htmlFor="password"
                            className="form-label flt-label"
                          >
                            OTP
                          </label>
                          <CommonTextInput
                            name="otp"
                            value={OTP}
                            className="form-control pe-5"
                            id="otp"
                            maxLength={6}
                            placeholder="Enter OTP"
                            onChange={({ target }) => setOTP(target?.value)}
                          />
                        </div>
                      )}
                      <div
                        style={{ marginTop: 20 }}
                        className="md-4 text-center"
                      >
                        <button
                          type="submit"
                          onClick={expandForm ? onOTPVerify : onSignup}
                          className="btn btn-info button-drk-info"
                        >
                          {expandForm ? "Verify" : "Send"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ConfirmMobile;
