const EndPoints = {
  // Login
  login: "auth-login",

  //Signup
  signup: "register",

  // Logout
  logout: "logout",

  verifyUser: "check-user",
  checkEmailOrMobileIsExistOrNot: "check-email-mobile",
  deleteFamilyMember: "delete-family-member",
  checkValideUser: "checkvaliduser",

  shareWithAnyOne: "shareWithAnyOne",
  shareWithPeople: "shareWithInvite",
  deleteSharedDoc: "deleteShareDoc",
  shareBusinessCard: "shareBusinessCard",
  deleteShareWithMe: "deleteShareWithMe",
  getUserPersonalInfo: "user_personal_info",
  updateUserPersonalInfo: "update_personal_info",
  updateShareLink: "updateShareDocuments",
  changeOrderOfFavourites: "favorites/update",

  getUserProffessionalInfo: "user_professional_info",
  updateUserProffessionalInfo: "update-professional-info",

  getUserBusinessCardInfo: "business-card",
  getUserBusinessCardSetting: "get_business_card_setting",
  updateUserBusinessCardInfo: "update_business_card_setting",
  updateFavoriteListData: "delete-favorite",
  getNotificationFrequency: "notification-document-frequency",
  getNotificationManagement: "get-notification-management",
  storeNotificaitionManagement: "store-notification-management",
  delete_all_doc: "delete-documents",
  getDocumentListById: "document-list-by-id",
  getSharedByMe: "get_shared_by_me",
  getSharedWithMe: "get_shared_with_me",
  getSharedDocDetail: "get_shared_doc_detail",
  saveSharedDocument: "save-share-doc",

  sendHelpCenterQuery: "help-center",

  deleteAccount: "delete-account",
  deleteMyData: "delete-my-data",
  changePassword: "change-password",
  getNotificationList: "notification-list",
  readNotification: "read-notification",
  readAllNotification: "read-all-notification",

  deleteAllNotification: "clear-notification-list",
  deleteNotification: "delete-notification",
  getPrivacyPolicy: "privacy-policy/1",
  getTermsOfUse: "term-service/1",
  getInviteFriend: "invite-friend/1",

  sendMail: "send-otp/mail",
  verifyMail: "verify-otp/mail",
  shareEmail: "share-otp/mail",
};

export default EndPoints;
