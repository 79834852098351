import { createSlice } from "@reduxjs/toolkit";

export const CommonInitialState = {
  frequencyList: [],
  notificationManagementList: [],
  notificationList: [],
  inviteFriendDesc: [],
  countryInfo: {},
  favoriteList: [],
  countryList: [],
  sharedByMe: [],
  sharedWithMe: [],
  documentListByFamily: [],
  documentListByDocType: [],
  relationList: [],
  googleMapList: [],
  documentFieldsList: [],
  documentList: [],
  familyMembersList: [],
  shareDocsDetails: {},
  documentType: "Document Type",
};

const CommonSlice = createSlice({
  name: "common",
  initialState: CommonInitialState,
  reducers: {
    RESET_DATA: (state) => {
      state.frequencyList = [];
      state.notificationManagementList = [];
      state.notificationList = [];
      state.inviteFriendDesc = [];
      state.countryInfo = {};
      state.favoriteList = [];
      state.countryList = [];
      state.sharedByMe = [];
      state.sharedWithMe = [];
      state.documentListByFamily = [];
      state.documentListByDocType = [];
      state.relationList = [];
      state.googleMapList = [];
      state.documentFieldsList = [];
      state.documentList = [];
      state.familyMembersList = [];
      state.shareDocsDetails = {};
      state.documentType = "Document Type";
    },
    NOTIFICATION_FREQUENCY_LIST: (state, action) => {
      state.frequencyList = action.payload;
    },
    NOTIFICATION_MANAGEMENT_LIST: (state, action) => {
      state.notificationManagementList = action.payload;
    },
    SET_NOTIFICATION_LIST: (state, action) => {
      state.notificationList = action.payload;
    },
    INVITE_FRIEND_DESC: (state, action) => {
      state.inviteFriendDesc = action.payload;
    },
    SET_COUNTRY_INFO: (state, action) => {
      state.countryInfo = action.payload;
    },
    GET_WISHLIST_SUCCESS: (state, action) => {
      state.favoriteList = action.payload || [];
    },
    SET_WISHLIST: (state, action) => {
      state.favoriteList = action.payload || [];
    },
    GET_COUNTRY_LIST_SUCCESS: (state, action) => {
      const newCountry = action?.payload || [];
      newCountry?.forEach((element) => {
        element.code_name = `${element?.country_code} (${element?.country_name})`;
      });
      state.countryList = newCountry;
    },
    SET_DOCUMENT_LIST_BY_FAMILY: (state, action) => {
      state.documentListByFamily = action?.payload || [];
    },
    SET_DOCUMENT_LIST_BY_DOCTYPE: (state, action) => {
      state.documentListByDocType = action?.payload || [];
    },
    SET_SHARED_BY_ME: (state, action) => {
      state.sharedByMe = action?.payload || [];
    },
    SET_SHARED_WITH_ME: (state, action) => {
      state.sharedWithMe = action?.payload || [];
    },
    SET_SHARED_DOC_DETAILS: (state, action) => {
      state.shareDocsDetails = action?.payload;
    },
    GET_RELATION_LIST_SUCCESS: (state, action) => {
      state.relationList = action?.payload || [];
    },
    GET_GOOGLEPLACE_LIST_SUCCESS: (state, action) => {
      state.googleMapList = action?.payload?.predictions || [];
    },
    GET_DOCUMENT_FIELDS_LIST_SUCCESS: (state, action) => {
      state.documentFieldsList = action?.payload || [];
    },
    GET_FAMILY_MEMBER_SUCCESS: (state, action) => {
      state.familyMembersList = action?.payload || [];
    },
    GET_DOCUMENT_TYPE_LIST_SUCCESS: (state, action) => {
      state.documentList = action?.payload || [];
    },
    GET_FAMILY_DOCUMENT_LIST_SUCCESS: (state, action) => {
      state.documentListByFamily = action.payload?.documentByMemberList || [];
      state.documentListByDocType =
        action.payload?.documentByDocumentTypeList || [];
    },
    DOCUMENT_TYPE_SELECTION: (state, action) => {
      state.documentType = action.payload;
    },
  },
});

const { actions, reducer: CommonReducer } = CommonSlice;

export const {
  NOTIFICATION_FREQUENCY_LIST,
  NOTIFICATION_MANAGEMENT_LIST,
  SET_NOTIFICATION_LIST,
  INVITE_FRIEND_DESC,
  SET_COUNTRY_INFO,
  GET_WISHLIST_SUCCESS,
  SET_WISHLIST,
  GET_COUNTRY_LIST_SUCCESS,
  SET_SHARED_BY_ME,
  SET_SHARED_WITH_ME,
  GET_FAMILY_DOCUMENT_LIST_SUCCESS,
  SET_SHARED_DOC_DETAILS,
  GET_RELATION_LIST_SUCCESS,
  SET_DOCUMENT_LIST_BY_DOCTYPE,
  SET_DOCUMENT_LIST_BY_FAMILY,
  GET_DOCUMENT_FIELDS_LIST_SUCCESS,
  GET_DOCUMENT_TYPE_LIST_SUCCESS,
  GET_FAMILY_MEMBER_SUCCESS,
  GET_GOOGLEPLACE_LIST_SUCCESS,
  DOCUMENT_TYPE_SELECTION,
  RESET_DATA,
} = actions;

export default CommonReducer;
