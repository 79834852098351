import { useDispatch } from "react-redux";
import { logoutAction } from "../../../redux/actions/AuthActions";
import CustomDialog from "../../../customComponents/customDialog/CustomDialog";
import i18n from "../../../i18n/i18n";

const useCommonSidebar = () => {
  const dispatch = useDispatch();

  // On Login Press
  const onLogOutPress = () => {
    CustomDialog.confirm(i18n.t("are_you_sure_want_to_logout"), () => {
      dispatch(logoutAction());
    });
  };

  return {
    onLogOutPress,
  };
};

export default useCommonSidebar;
