import React, { Suspense, lazy } from "react";
import TopBarProgress from "react-topbar-progress-indicator";

export const allRoutes = {
  dashboard: "/dashboard",
  addDocument: "/add_document",
  addFamilyDoc: "/add_family_doc",
  addFamilyMember: "/add_family_member",
  allDocUpload: "/all_doc_upload",
  businessCard: "/business_card",
  editDocument: "/edit_document",
  editProfile: "/edit_profile",
  familyMember: "/family_member",
  helpCenter: "/helpCenter",
  familyMemberEdit: "/family_member_edit",
  favorites: "/favorites",
  login: "/login",
  signUp: "/signUp",
  confirmMobile: "/confirmMoile",
  noDocument: "/no_document",
  profile: "/profile",
  settings: "/settings",
  shareLink: "/share_links",
  shareManagement: "/share_management",
  forgotPassword: "/forgotPassword",
  editShare: "/editShare",
  changePassword: "/changePassword",
  termPolicy: "/termPolicy",
  notificationManagement: "/notificationManagement",
};

const SuspensedView = ({ children }) => {
  TopBarProgress.config({
    barColors: {
      0: "#1F508E",
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const AddDocument = lazy(() => import("../pages/addDocument/AddDocument"));
const AddFamilyDoc = lazy(() => import("../pages/addFamilyDoc/AddFamilyDoc"));
const AddFamilyMember = lazy(() =>
  import("../pages/addFamilyMember/AddFamilyMember")
);
const AllDocumentUpload = lazy(() =>
  import("../pages/allDocUpload/AllDocUpload")
);
const BusinessCard = lazy(() => import("../pages/businessCard/BusinessCard"));
const EditDocument = lazy(() => import("../pages/editDocument/EditDocument"));
const EditProfile = lazy(() => import("../pages/editProfile/EditProfile"));
const FamilyMember = lazy(() => import("../pages/familyMember/FamilyMember"));
const FamilyMemberEdit = lazy(() =>
  import("../pages/familyMemberEdit/FamilyMemberEdit")
);
const Favorites = lazy(() => import("../pages/favorites/Favorites"));
const Login = lazy(() => import("../pages/login/Login"));
const NoDocument = lazy(() => import("../pages/noDocument/NoDocument"));
const Profile = lazy(() => import("../pages/profile/Profile"));
const Settings = lazy(() => import("../pages/settings/Settings"));
const ShareLink = lazy(() => import("../pages/shareLink/ShareLink"));
const ShareManagement = lazy(() =>
  import("../pages/shareManagement/ShareManagement")
);
const SignUp = lazy(() => import("../pages/signUp/SignUp"));
const ForgotPassword = lazy(() =>
  import("../pages/forgot_password/ForgotPassword")
);
const ConfirmMobile = lazy(() =>
  import("../pages/confirmMobile/ConfirmMobile")
);
const HelpCenter = lazy(() => import("../pages/helpCenter/HelpCenter"));
const EditShareDocument = lazy(() =>
  import("../pages/editShare/EditShareDocument")
);
const ChangePassword = lazy(() =>
  import("../pages/changePassword/ChangePassword")
);
const TermPolicy = lazy(() => import("../pages/termPolicy/TermPolicy"));
const NotificationManagement = lazy(() =>
  import("../pages/notificationManagement/NotificationManagement")
);

const RoutesPath = [
  {
    path: allRoutes.dashboard,
    element: (
      <SuspensedView>
        <Dashboard />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.addDocument,
    element: (
      <SuspensedView>
        <AddDocument />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.addFamilyDoc,
    element: (
      <SuspensedView>
        <AddFamilyDoc />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.addFamilyMember,
    element: (
      <SuspensedView>
        <AddFamilyMember />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.allDocUpload,
    element: (
      <SuspensedView>
        <AllDocumentUpload />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.businessCard,
    element: (
      <SuspensedView>
        <BusinessCard />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.editDocument,
    element: (
      <SuspensedView>
        <EditDocument />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.editProfile,
    element: (
      <SuspensedView>
        <EditProfile />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.familyMember,
    element: (
      <SuspensedView>
        <FamilyMember />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.familyMemberEdit,
    element: (
      <SuspensedView>
        <FamilyMemberEdit />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.favorites,
    element: (
      <SuspensedView>
        <Favorites />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.noDocument,
    element: (
      <SuspensedView>
        <NoDocument />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.profile,
    element: (
      <SuspensedView>
        <Profile />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.settings,
    element: (
      <SuspensedView>
        <Settings />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.shareLink,
    element: (
      <SuspensedView>
        <ShareLink />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.shareManagement,
    element: (
      <SuspensedView>
        <ShareManagement />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.helpCenter,
    element: (
      <SuspensedView>
        <HelpCenter />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.editShare,
    element: (
      <SuspensedView>
        <EditShareDocument />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.changePassword,
    element: (
      <SuspensedView>
        <ChangePassword />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.termPolicy,
    element: (
      <SuspensedView>
        <TermPolicy />
      </SuspensedView>
    ),
  },
  {
    path: allRoutes.notificationManagement,
    element: (
      <SuspensedView>
        <NotificationManagement />
      </SuspensedView>
    ),
  },
];

export default RoutesPath;
