import { createSlice } from "@reduxjs/toolkit";
import { APP_LANGUAGES } from "../../i18n/i18n";

export const AuthInitialState = {
  userData: [],
  token: null,
  userPersonalData: null,
  userProfessionalData: null,
  userBusinessCard: null,
  userBusinessCardSetting: null,
  appLanguage: APP_LANGUAGES.EN,
  isRTL: false,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: AuthInitialState,
  reducers: {
    SET_TOKEN: (state, action) => {
      state.token = action.payload;
    },
    SET_USER_DATA: (state, action) => {
      state.userData = action.payload;
    },
    RESET_USER_DATA: (state) => {
      state.userData = [];
      state.token = null;
      state.appLanguage = APP_LANGUAGES.EN;
      state.isRTL = false;
      state.userPersonalData = null;
      state.userProfessionalData = null;
      state.userBusinessCard = null;
      state.userBusinessCardSetting = null;
    },
    UPDATE_PERSONAL_DATA: (state, action) => {
      state.userPersonalData = action.payload;
      state.userData = { ...state.userData, email: action?.payload?.email };
    },
    UPDATE_PROFESSIONAL_DATA: (state, action) => {
      state.userProfessionalData = action.payload;
    },
    UPDATE_BUSINESS_CARD_DATA: (state, action) => {
      state.userBusinessCard = action.payload;
    },
    UPDATE_BUSINESS_CARD_SETTING: (state, action) => {
      state.userBusinessCardSetting = {
        ...state.userBusinessCardSetting,
        ...action.payload,
      };
    },
    SET_APP_LANGUAGE: (state, action) => {
      state.appLanguage = action.payload;
      state.isRTL = !!["ar", "he", "fa"].includes(action.payload);
    },
  },
});

const { actions, reducer: AuthReducer } = AuthSlice;

export const {
  SET_USER_DATA,
  SET_TOKEN,
  SET_USER_CREDENTIALS,
  RESET_USER_DATA,
  UPDATE_PERSONAL_DATA,
  UPDATE_PROFESSIONAL_DATA,
  UPDATE_BUSINESS_CARD_DATA,
  UPDATE_BUSINESS_CARD_SETTING,
  SET_APP_LANGUAGE,
} = actions;

export default AuthReducer;
