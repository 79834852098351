import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Router from "./routes";
import "./App.css";
import Toastify from "./toastify/Toastify";
import { SET_COUNTRY_INFO } from "./redux/reducers/commonReducer";
import { ConfirmDialog } from "primereact/confirmdialog";

function App() {
  const dispatch = useDispatch();
  const getGeoInfo = async () => {
    const url = "https://ipinfo.io/json?token=6da00a4bbb4340";
    const response = await fetch(url);
    const data = await response.json();
    dispatch({ type: SET_COUNTRY_INFO, payload: data });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);
  return (
    <>
      <Router />
      <Toastify />
      <ConfirmDialog />
    </>
  );
}

export default App;
