import { DOC_TYPE_ICONS } from "../assets/images/images";

export const typeViseFavoritCard = {
    17: {
        name: 'residency_visa',
        icon: DOC_TYPE_ICONS.residency_visa,
    },
    19: {
        name: 'birth',
        icon: DOC_TYPE_ICONS.birth_certificate,
    },
    21: {
        name: 'driving_licence',
        icon: DOC_TYPE_ICONS.driving_licence_detail,
    },
    24: {
        name: 'bank_account',
        icon: DOC_TYPE_ICONS.bank_account,
    },
    25: {
        name: 'car',
        icon: DOC_TYPE_ICONS.car_insurance,
    },
    26: {
        name: 'baptism',
        icon: DOC_TYPE_ICONS.baptism,
    },
    29: {
        name: 'vaccination',
        icon: DOC_TYPE_ICONS.vaccination,
    },
    30: {
        name: 'employment',
        icon: DOC_TYPE_ICONS.employmentCard,
    },
    31: {
        name: 'marriage',
        icon: DOC_TYPE_ICONS.marriageCard,
    },
    32: {
        name: 'degrees',
        icon: DOC_TYPE_ICONS.degree_card,
    },
    33: {
        name: 'tenancy_contract',
        icon: DOC_TYPE_ICONS.tenancy_contract,
    },
    34: {
        name: 'id',
        icon: DOC_TYPE_ICONS.idCard,
    },
    35: {
        name: 'insurance',
        icon: DOC_TYPE_ICONS.insurance,
    },
    36: {
        name: 'booking_hotel',
        icon: DOC_TYPE_ICONS.booking_hotel,
    },
    37: {
        name: 'itinerary',
        icon: DOC_TYPE_ICONS.itinerary,
    },
    38: {
        name: 'resume_cover_letter',
        icon: DOC_TYPE_ICONS.resume_cover_letter,
    },
    39: {
        name: 'divorce',
        icon: DOC_TYPE_ICONS.divorce_certificate,
    },
    40: {
        name: 'death',
        icon: DOC_TYPE_ICONS.death_certificate,
    },
    44: {
        name: 'will',
        icon: DOC_TYPE_ICONS.will,
    },
    49: {
        name: 'loyality',
        icon: DOC_TYPE_ICONS.loyalty_card_detail,
    },
    46: {
        name: 'passport',
        icon: DOC_TYPE_ICONS.passport,
    },
    48: {
        name: 'photo_passport',
        icon: DOC_TYPE_ICONS.photo_passport,
    },
}