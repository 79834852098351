import axios from "axios";
import { BASE_URL, DevConfig } from "../constants/constants";
import { getAuthToken } from "../redux/selectors/AuthSelectors";
import { store } from "../redux/store";
import { logoutAction } from "../redux/actions/AuthActions";

const axiosinstance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
});

axiosinstance.interceptors.request.use(async (config) => {
  const state = store.getState();
  const token = getAuthToken(state);
  config.headers["Authorization"] = `Bearer ${token}`;
  if (DevConfig.ENABLE_CONSOLE_LOGS) return config;
});

axiosinstance.interceptors.response.use(
  (res) => {
    if (DevConfig.ENABLE_CONSOLE_LOGS) return Promise.resolve(res?.data);
  },
  async (error) => {
    if (DevConfig.ENABLE_CONSOLE_LOGS)
      if (error?.response?.status === 401) store.dispatch(logoutAction(true));
    return Promise.reject(error?.response);
  }
);

export default axiosinstance;
