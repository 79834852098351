import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import ar from './locales/ar.json';
import fr from './locales/fr.json';
import de from './locales/de.json';
import es from './locales/es.json';

export const APP_DIRECTION = {
    ltr: 'ltr',
    rtl: 'rtl',
}
export const APP_LANGUAGES = {
    EN: 'en',
    AR: 'ar',
    FR: 'fr',
    DE: 'de',
    ES: 'es',
}

i18n
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
        resources: {
            en: { translation: en },
            ar: { translation: ar },
            fr: { translation: fr },
            de: { translation: de },
            es: { translation: es },
        },
    });

i18n.changeLanguage(APP_LANGUAGES.EN);

export default i18n;
