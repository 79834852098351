import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAZsvLYGiP_OMR-SdTD1uKbDG5KYd228j8",
  authDomain: "doxbox-4b5ab.firebaseapp.com",
  projectId: "doxbox-4b5ab",
  storageBucket: "doxbox-4b5ab.appspot.com",
  messagingSenderId: "325542267645",
  appId: "1:325542267645:web:845d317eb99afc3cebcc94",
  measurementId: "G-0T51HVRHX5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
