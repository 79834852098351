import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../components/layout";
import ProtectedRoutes from "../protectedRoutes";
import { allRoutes } from "./routes";
import ForgotPassword from "../pages/forgot_password/ForgotPassword";
import Login from "../pages/login/Login";
import SignUp from "../pages/signUp/SignUp";
import ConfirmMobile from "../pages/confirmMobile/ConfirmMobile";
const Router = () => {
  return (
    // <BrowserRouter basename="/">
    //   <Routes>
    //     <Route>
    //         <Route path="*" name="Home" element={<Layout />} />
    //     </Route>
    //   </Routes>
    // </BrowserRouter>
    <BrowserRouter basename="/">
      <Routes>
        <Route>
          <Route element={<ProtectedRoutes type={"before_login"} />}>
            <Route path={allRoutes.login} name="Login" element={<Login />} />
            <Route
              path={allRoutes.forgotPassword}
              name="ForgotPassword"
              element={<ForgotPassword />}
            />
            <Route path={allRoutes.signUp} name="SignUp" element={<SignUp />} />
            <Route
              path={allRoutes.confirmMobile}
              name="Mobile"
              element={<ConfirmMobile />}
            />
          </Route>
          <Route element={<ProtectedRoutes type={"after_login"} />}>
            <Route path="*" name="Home" element={<Layout />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
